var Environment = getEnvironment();
function getEnvironment() {
    const PLATFORM = process.env.REACT_APP_PLATFORM;

    let platform  //getPlatform();
    if (PLATFORM === "FF-INT") {
      platform = "development";
    } else if (PLATFORM === "TEST") {
      platform = "test";
    } else if (PLATFORM === "STAGE") {
      platform = "stage";
    } else if (PLATFORM === "PROD") {
      platform = "production";
    }
const clientInfo = {
    "stage" : [
        { brand : "FF", country: 'HK', clientId: '59A92D6D-608E-46D7-BDE6-1F54C3F83B32' },
        { brand : "5E", country: 'HK',clientId: '30AEB6B5-E539-405F-9120-C10481DE8631' }
    ],
    "production": [
        { brand : "FF", country: 'HK', clientId: '093B3651-3BDD-4957-9300-BB09B9733AFC' },
        { brand : "5E", country: 'HK',clientId: '2EEC575A-2B57-4F20-9F23-257560A10B91' }
    ]
}
const SSO = {
    clientInfo:clientInfo[platform],
    OPServer: platform === 'stage' ? 'https://id.uat.circuithq.com/' : 'https://id.circuithq.com/',
    redirect_uri: '/callback',
    scope: 'openid profile member-data offline_access',
    userInfoEndpoint: 'connect/userinfo',
    extra: {prompt: 'consent', access_type: 'offline'}
}
var _Environments = {
    production: { SSO, BASE_URL: 'https://api.wexer.com/', API_KEY: 'a0ddf4dc5b0b4b628e5a4c27b93c988e',JWPLAYER:'95riWD5s.js', API_SECRET: '711ff01003764889987158b329c7e6c0' },
    stage: { SSO, BASE_URL: 'https://stage.api.wexer.com/', API_KEY: '1234123412341234', JWPLAYER:'wUkkXJdm.js',API_SECRET: '573572796441127398913495789868468206481' },
    development : { SSO, BASE_URL: 'https://ff-int.api.wexer.com/', API_KEY: '1234123412341234',JWPLAYER:'HofBP5zj.js', API_SECRET: '573572796441127398913495789868468206481' },
    test : { SSO, BASE_URL: "https://test.api.wexer.com/", API_KEY: '50703717552133197901571613251413029814',JWPLAYER:'HofBP5zj.js', API_SECRET: '51749401073929517144660310291806967949' },
    uat: { SSO, BASE_URL: 'https://uat.api.wexer.com/', API_KEY: '1234123412341234', JWPLAYER:'wUkkXJdm.js', API_SECRET: '573572796441127398913495789868468206481' },
}
return _Environments[platform];
}

module.exports = Environment 